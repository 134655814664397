import { lowerCaseValue } from './lowerCaseValue';

export function normalizeSearchQuery(query: string): string {
    // Normalize
    let normalized = lowerCaseValue(query);

    // Consolidate spaces
    if (normalized) {
        normalized = normalized.replace(/\s+/g, ' ');
    }

    return normalized;
}
