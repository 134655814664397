import { isValid, parseISO } from 'date-fns';
import { normalizeValue } from '../strings/normalizeValue';

function isNumber(value: string): boolean {
    const normalized = normalizeValue(value);
    if (!normalized) {
        return false;
    }

    return parseInt(normalized).toString() === value;
}

function parseDate(date: string): Date {
    if (isNumber(date)) {
        return undefined;
    }

    try {
        const parsed = parseISO(date);
        if (isValid(parsed)) {
            return parsed;
        }
    } catch {
        // Unable to parse date
    }

    return undefined;
}

export function getDate(date: string | Date): Date {
    if (date instanceof Date) {
        return date;
    }

    const normalized = normalizeValue(date);
    if (!normalized) {
        return undefined;
    }

    return parseDate(normalized);
}
