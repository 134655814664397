import { normalizeValue } from './normalizeValue';

function checkForReplacementError(tokenName: string, tokenValue: string, oldValue: string, newValue) {
    // No change
    if (oldValue === newValue) {
        return;
    }

    // We tried setting a value that wasn't defined
    if (!tokenValue?.length) {
        throw new Error(`Token: [ ${tokenName} ] is blank or undefined`);
    }
}

export function replaceTokens(equation: string, tokens?: Record<string, string>): string {
    let replaced = normalizeValue(equation);
    if (!replaced?.length) {
        return replaced;
    }

    // Sort the list so that the longest strings come first
    // This avoids issues if we have a token that's a substring of another token
    const tokenEntries = Object.entries(tokens);
    tokenEntries.sort(([, a], [, b]) => b.length - a.length);

    for (const [tokenName, tokenValue] of tokenEntries) {
        const replacementValue = normalizeValue(tokenValue) || '';
        const updated = replaced.replaceAll(tokenName, replacementValue);

        // Make sure we were able to successfully replace the token
        checkForReplacementError(tokenName, tokenValue, replaced, updated);

        replaced = updated;
    }

    return replaced;
}
