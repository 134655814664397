'use client';

import React, { useCallback, useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './page.module.scss';
import { SearchResults } from '@/common/interfaces/admin/search/SearchResults';
import AuthenticatedJsonContent from '../../(authenticated)/content/jsonGet/template';
import { getSearchURL } from './getSearchURL';
import { mutate } from 'swr';
import { PremiseResult } from '@/common/interfaces/admin/search/PremiseResult';
import { filterEmpty } from '@/common/util/lists/filterEmpty';
import Link from 'next/link';
import { JobResult } from '@/common/interfaces/admin/search/JobResult';
import { lowerCaseValue } from '@/common/util/strings/lowerCaseValue';
import { FocusableContainer } from '../../../components/FocusableContainer';
import { usePathChanged } from '../../../hooks/usePathChanged';
import { upperCaseValue } from '@/common/util/strings/upperCaseValue';
import { formatLongDateTime } from '@/components/Tables/formatShortDateTime';
import { useDebouncedValue } from './useDebouncedValue';
import { titleCaseValue } from '@/common/util/strings/titleCaseValue';

function refresh() {
    const path = `/authenticated/data`;
    void mutate((key) => typeof key === 'string' && key.includes(path), undefined, { revalidate: true });
}

interface SearchParams {
    params: {
        queryInput: string;
        setQueryInput: (string) => void;
        show: boolean;
    };
}

function SearchInput(params: SearchParams): JSX.Element {
    const queryInput = params.params.queryInput;
    const setQueryInput = params.params.setQueryInput;

    const changeQueryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        if (value !== queryInput) {
            setQueryInput(value);
        }
    };

    let placeholder = 'Search for Premise or Job';
    if (queryInput?.length) {
        placeholder = '';
    }

    return (
        <Form.Control
            id="searchQuery"
            type="search"
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            spellCheck={false}
            onChange={changeQueryInput}
            defaultValue={queryInput}
            placeholder={placeholder}
            aria-controls="searchResults"
            aria-haspopup="menu"
        ></Form.Control>
    );
}

interface SearchResultsParams {
    params: {
        queryInput: string;
        searchResults: SearchResults;
        show?: boolean;
        premiseResult?: PremiseResult;
        jobResult?: JobResult;
        loading?: boolean;
    };
}

function longestTokenMatch(value: string, query: string): string {
    const lowerValue = lowerCaseValue(value);
    if (!lowerValue) {
        return undefined;
    }

    const tokens = query.split(' ');
    let longestMatch;

    for (const token of tokens) {
        // Do case insensitive matching
        const lowerToken = lowerCaseValue(token);

        // Only looking for things that start with the token
        if (!lowerValue.startsWith(lowerToken)) {
            continue;
        }

        // First match
        if (!longestMatch) {
            longestMatch = token;
        }

        // This token is longer than previous matches
        if (token.length > longestMatch.length) {
            longestMatch = token;
        }
    }

    return longestMatch;
}

interface HighlightedPartsParams {
    params: {
        value: string;
        query: string;
        titleize?: boolean;
    };
}

function HighlightedParts(params: HighlightedPartsParams): JSX.Element[] {
    let value = params?.params.value;
    if (!value) {
        return [<span key={0}>&nbsp;</span>];
    }

    if (params?.params?.titleize) {
        value = titleCaseValue(params?.params.value);
    }
    const query = params?.params?.query;

    const valueParts = value.split(' ');

    const highlightedParts: JSX.Element[] = valueParts.map((part, index) => {
        const token = longestTokenMatch(part, query);
        const tokenLength = token?.length;

        let ending = ' ';
        if (index === valueParts.length - 1) {
            ending = null;
        }

        // No tokens matched
        if (!tokenLength) {
            return (
                <span key={index}>
                    {part}
                    {ending}
                </span>
            );
        }

        // Got a match
        const before = part.substring(0, tokenLength);
        const after = part.substring(tokenLength);
        return (
            <span key={index}>
                <b>{before}</b>
                {after}
                {ending}
            </span>
        );
    });

    return highlightedParts;
}

function Line2Cell(params: SearchResultsParams): JSX.Element {
    const premiseResult = params?.params?.premiseResult;
    if (!premiseResult) {
        return null;
    }
    const premises = params?.params?.searchResults?.premises || [];
    const showLine2 = premises.find((findValue) => findValue?.premiseData?.data?.address?.addressLine2);
    if (!showLine2) {
        return null;
    }

    const address = premiseResult?.premiseData?.data?.address;
    const queryInput = params?.params?.queryInput;
    return (
        <td>
            <Link href={`/premises/?premiseId=${premiseResult.premiseId}`}>
                <HighlightedParts params={{ query: queryInput, value: address.addressLine2, titleize: true }} />
            </Link>
        </td>
    );
}

function PremiseResultDisplay(params: SearchResultsParams): JSX.Element {
    const premiseResult = params?.params?.premiseResult;
    if (!premiseResult) {
        return null;
    }

    const queryInput = params?.params?.queryInput;

    // TODO: if we're searching by GPS, include the distance in the selection list
    const address = premiseResult?.premiseData?.data?.address;

    // TODO: remove zip from segments list in master data, then remove it from here

    return (
        <>
            <tr>
                <td>
                    <Link href={`/premises/?premiseId=${premiseResult.premiseId}`}>
                        <HighlightedParts params={{ query: queryInput, value: address.addressLine1, titleize: true }} />
                    </Link>
                </td>
                <Line2Cell params={params?.params} />
                <td>
                    <HighlightedParts params={{ query: queryInput, value: address.city, titleize: true }} />
                </td>
                <td>
                    <HighlightedParts params={{ query: queryInput, value: address.state }} />
                </td>
            </tr>
        </>
    );
}

function JobResultDisplay(params: SearchResultsParams): JSX.Element {
    const jobResult = params?.params?.jobResult;
    const jobStatus = jobResult?.jobStatus;
    if (!jobStatus) {
        return null;
    }

    const jobDisplayId = upperCaseValue(jobStatus?.data?.jobDisplayId);
    const created = jobStatus?.data?.jobCreateTime;

    return (
        <tr>
            <td>
                <Link href={`/jobs/?jobId=${jobResult.jobId}`}>{jobDisplayId}</Link>
            </td>
            <td>{jobStatus.data?.jobType}</td>
            <td>{formatLongDateTime(created)}</td>
        </tr>
    );
}

function getJobSearchList(searchResults: SearchResults, queryInput: string): JSX.Element {
    let jobs = searchResults?.jobs || [];
    // Just get the first 10 elements for display
    jobs = jobs.slice(0, 10);

    let jobOptions = jobs.map((job) => {
        return (
            <JobResultDisplay
                key={job.jobId}
                params={{ queryInput: queryInput, searchResults: searchResults, jobResult: job }}
            />
        );
    });

    // Filter out anything with null values
    jobOptions = filterEmpty(jobOptions);
    if (!jobOptions?.length) {
        return null;
    }

    return (
        <>
            <h4>Jobs</h4>
            <table>
                <thead className="sr-only">
                    <tr>
                        <th>Job</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Created Time</th>
                        <th>Restored Date</th>
                        <th>Restored Time</th>
                    </tr>
                </thead>
                <tbody>{jobOptions}</tbody>
            </table>
        </>
    );
}

function getPremiseSearchList(searchResults: SearchResults, queryInput: string): JSX.Element {
    let premises = searchResults?.premises || [];
    // Just get the first 10 elements for display
    premises = premises.slice(0, 10);

    let premiseOptions = premises.map((premise) => {
        return (
            <PremiseResultDisplay
                key={premise.premiseId}
                params={{ queryInput: queryInput, searchResults: searchResults, premiseResult: premise }}
            />
        );
    });

    // Filter out anything with null values
    premiseOptions = filterEmpty(premiseOptions);
    if (!premiseOptions?.length) {
        return null;
    }

    const showLine2 = premises.find((findValue) => findValue?.premiseData?.data?.address?.addressLine2);
    let line1Header = <th>Address</th>;
    let line2Header = null;
    if (showLine2) {
        line1Header = <th>Address Line 1</th>;
        line2Header = <th>Address Line 2</th>;
    }

    return (
        <>
            <h4>Premises</h4>
            <table>
                <thead className="sr-only">
                    <tr>
                        {line1Header}
                        {line2Header}
                        <th>City</th>
                        <th>State</th>
                        <th>Zipcode</th>
                    </tr>
                </thead>
                <tbody>{premiseOptions}</tbody>
            </table>
        </>
    );
}

function SearchResultsSelection(params: SearchResultsParams): JSX.Element {
    const queryInput = params?.params?.queryInput;
    const searchResults = params?.params?.searchResults;
    const show = params?.params?.show;

    let content = null;
    if (show) {
        const premiseListContent = getPremiseSearchList(searchResults, queryInput);

        if (premiseListContent !== null) {
            content = premiseListContent;
        } else {
            const jobListContent = getJobSearchList(searchResults, queryInput);
            if (jobListContent !== null) {
                content = jobListContent;
            } else {
                const loading = params?.params?.loading;
                if (loading) {
                    content = <>Loading...</>;
                } else if (queryInput?.length) {
                    content = <>No matches</>;
                } else {
                    content = <>Enter an id or an address to search</>;
                }
            }
        }
    }

    // Wrap content in a div if we have it
    if (content) {
        content = <div className="content">{content}</div>;
    }

    return (
        <div id="searchContent" className={styles.selectionList} role="menu" aria-roledescription="Search Results">
            {content}
        </div>
    );
}

export default function SearchBar(): JSX.Element {
    // const router = useRouter();

    const [queryInput, setQueryInput] = useState<string>('');

    // Rely on a debounced value so that we don't trigger request as often when user is typing
    const debouncedQueryInput = useDebouncedValue(queryInput, 100);
    const url = getSearchURL(debouncedQueryInput);

    const [searchResults, setSearchResults] = useState<SearchResults>();
    const [loading, setLoading] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const handleShow = useCallback(() => {
        setShowOverlay(true);
    }, []);
    const handleHide = useCallback(() => {
        setShowOverlay(false);
    }, []);

    // Hide the results if we select something
    const changePath = useCallback(() => {
        // Clear the query
        setQueryInput('');

        // Hide the modal
        setShowOverlay(false);
    }, []);
    usePathChanged(changePath);

    const search = (event: React.FormEvent<HTMLFormElement>) => {
        if (!loading) {
            // On manually pressing search, clear caches and refresh
            refresh();
        }
        // Call prevent default becase we don't want to submit the form and cause a page refresh
        event.preventDefault();
    };

    let button = <FontAwesomeIcon icon={faMagnifyingGlass} />;
    if (loading) {
        button = <FontAwesomeIcon icon={faSpinner} spin={true} />;
    }

    return (
        <section className={styles.main}>
            <Form onSubmit={search}>
                <Form.Label htmlFor="searchQuery" className="sr-only">
                    Search
                </Form.Label>
                <Col className="inputColumn">
                    <FocusableContainer onFocus={handleShow} onBlur={handleHide}>
                        <Button
                            type="submit"
                            className="icon-button"
                            title="Search"
                            aria-controls="searchResults"
                            aria-expanded={showOverlay}
                        >
                            {button}
                        </Button>
                        <SearchInput
                            params={{
                                queryInput: queryInput,
                                setQueryInput: setQueryInput,
                                show: showOverlay,
                            }}
                        />
                        <SearchResultsSelection
                            params={{
                                queryInput: queryInput,
                                searchResults: searchResults,
                                loading: loading,
                                show: showOverlay,
                            }}
                        />
                    </FocusableContainer>
                </Col>
            </Form>
            <AuthenticatedJsonContent
                params={{ url: url, setData: setSearchResults, setLoading: setLoading, loadingContent: null }}
            />
        </section>
    );
}
