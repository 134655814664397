'use client';
import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { usePrevious } from '@/hooks/usePrevious';

export function usePathChanged(trigger: () => void) {
    const pathname = usePathname();
    const previousPathname = usePrevious(pathname);

    // Close the selection when we navigate
    useEffect(() => {
        if (pathname !== previousPathname) {
            trigger();
        }
    }, [pathname, previousPathname, trigger]);

    const searchParams = useSearchParams();
    const previousSearchParams = usePrevious(searchParams);

    // Close the selection when we navigate
    useEffect(() => {
        if (searchParams !== previousSearchParams) {
            trigger();
        }
    }, [searchParams, previousSearchParams, trigger]);
}
