import titleize from 'titleize';
import { normalizeValue } from './normalizeValue';

export function titleCaseValue(value: string) {
    const formatted = normalizeValue(value);

    if (!formatted) {
        return formatted;
    }

    return titleize(formatted);
}
